<script>
	import '../app.css';

	import { Toaster } from '$lib/components/ui/sonner';
	import { toast } from 'svelte-sonner';
	import { getFlash } from 'sveltekit-flash-message';
	import { page } from '$app/stores';
	import SvelteSeo from 'svelte-seo';

	const flash = getFlash(page);

	$: if ($flash) {
		if ($flash.type === 'success') {
			toast.success($flash.message);
		} else if ($flash.type === 'error') {
			toast.error($flash.message);
		}

		$flash = undefined;
	}
</script>

<SvelteSeo
	applicationName="Frag den Lenßen"
	openGraph={{
		type: 'website',
		locale: 'de_DE',
		url: 'https://frag-den-lenssen.de',
		site_name: 'Frag den Lenßen',
		images: [
			{
				url: 'https://frag-den-lenssen.de/ogimage.png',
				width: 1200,
				height: 630,
				alt: 'Frag den Lenßen - Frag meinen digitalen Zwilling! Ich helfe Dir absolut kostenlos und sofort zu Deinem Recht. Das ist mein Antrieb, mein Bestreben und meine Motivation. Dein Ingo Lenßen'
			}
		]
	}}
/>
<slot></slot>
<Toaster richColors position="bottom-right" />
