<script lang="ts">
	import Badge from '../badge.svelte';
	import Button from '../button.svelte';
</script>

<div class="container py-12">
	<div class="flex grid-cols-12 flex-col gap-12 lg:grid">
		<div class="col-span-7 flex flex-col justify-center">
			<Badge class="mb-3">Unser Werbepartner für Frag den Lenßen</Badge>
			<div class="mb-3.5 flex flex-col text-2xl font-medium text-gray-800 sm:text-3xl md:text-4xl">
				<span>Sichere Deine Zukunft mit der</span>
				<a target="_blank" href="/api/r/barmenia" class="font-bold"
					>Barmenia Berufsunfähigkeitsversicherung</a
				>
			</div>
			<div class="prose mt-6 text-lg prose-a:font-bold prose-a:no-underline">
				<p>
					Ich, Ingo Lenßen, empfehle Dir die <a href="/api/r/barmenia" target="_blank">
						Barmenia Berufsunfähigkeitsversicherung
					</a>, weil Du mir wichtig bist. Deine Arbeitskraft ist das Fundament, um Deinen
					Lebensunterhalt und den Deiner Familie zu sichern – und der Verlust stellt ein erhebliches
					Risiko dar.
				</p>
				<p>
					Mit der <a href="/api/r/barmenia" target="_blank">
						Barmenia Berufsunfähigkeitsversicherung
					</a> kannst Du dich optimal absichern und auf eine Lösung vertrauen, die genau auf Deine
					persönlichen Bedürfnisse und Wünsche abgestimmt ist.
				</p>
				<ul>
					<li>Optimale Absicherung bei Berufs- und optional bei Arbeitsunfähigkeit</li>
					<li>Individuell an Deinen heutigen Bedarf angepasst</li>
					<li>Flexible Zukunftsoptionen</li>
				</ul>
			</div>
			<div class="mt-7">
				<Button variant="barmenia" target="_blank" href="/api/r/barmenia"
					>Jetzt beraten lassen</Button
				>
			</div>
		</div>
		<div class="col-span-5">
			<enhanced:img loading="lazy" src="../../../assets/ads/barmenia.png" class="object-cover" />
		</div>
	</div>
</div>
