<script lang="ts">
	import Badge from '../badge.svelte';
	import Button from '../button.svelte';
</script>

<div class="container py-12">
	<div class="flex grid-cols-12 flex-col gap-12 lg:grid">
		<div class="col-span-7 flex flex-col justify-center">
			<Badge class="mb-3">Unser Werbepartner für Frag den Lenßen</Badge>
			<div class="font-regular mb-3.5 flex flex-col text-2xl text-gray-800 sm:text-3xl md:text-4xl">
				<span class="font-bold">Mein Tipp für Dich -</span>
				<span>Finanzen für Dich</span>
			</div>
			<div class="prose mt-6 text-lg prose-a:font-bold prose-a:no-underline">
				<p>
					Ich weiß, wie wichtig es ist, den Überblick über seine Finanzen zu behalten – gerade in
					unsicheren Zeiten. <a href="/api/r/finanzguru" target="_blank"> Finanzguru </a>
					aus der TV-Show <b>“Die Höhle der Löwen”</b> hilft Dir dabei, genau das zu tun. Mit dieser
					kostenlosen App kannst Du all Deine Konten sicher verbinden – von Girokonto über Depot bis
					zur Krypto-Börse. Finanzguru zeigt Dir in Echtzeit, wie viel Geld Dir tatsächlich zur Verfügung
					steht und hilft Dir, Dein Budget clever und nachhaltig zu planen.
				</p>
				<p><b>Warum Finanzguru?</b></p>
				<ul>
					<li>
						<a href="/api/r/finanzguru" target="_blank">Kostenlose Registrierung</a>
						und Nutzung
					</li>
					<li><b>Sichere</b> Daten durch anerkannte Sicherheitsstandards</li>
					<li><b>Einfache Anmeldung</b> – kein großer Aufwand</li>
					<li>Finanzguru Plus 7 Tage <b>kostenlos testen</b></li>
				</ul>
				<p>Übernimm jetzt die Kontrolle über Deine Finanzen – mit nur wenigen Klicks!</p>
			</div>
			<div class="mt-7">
				<Button variant="finanzguru" target="_blank" href="/api/r/finanzguru"
					>Jetzt kostenlos registrieren <span class="hidden md:inline">und durchstarten</span
					></Button
				>
			</div>
		</div>
		<div class="col-span-5 lg:mr-break-out">
			<a href="/api/r/finanzguru" target="_blank">
				<enhanced:img
					src="../../../assets/ads/finanzguru.png?w=1300;640"
					loading="lazy"
					class="w-full object-cover"
					sizes="(min-width: 1024px) 1300px, 640px"
				/>
				<span class="sr-only"> Finanzguru - Deine Finanzen im Blick </span>
			</a>
		</div>
	</div>
</div>
