<script lang="ts">
	import { page } from '$app/stores';
	import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
	import * as Sheet from '$lib/components/ui/sheet';
	import { UserIcon, ChevronDownIcon, MenuIcon } from 'lucide-svelte';
	import { ScrollArea } from './ui/scroll-area';
	import { Button } from './ui/button';

	let { isTransparent = false }: { isTransparent?: boolean } = $props();

	const MENU_ITMES = [
		{
			title: 'Über uns',
			href: '/ueber-uns'
		},
		{
			title: 'So fragst Du',
			href: '/so-fragst-du'
		},
		{
			title: 'Rechtsgebiete',
			href: '/rechtsgebiete',
			items: [
				{
					title: 'Erbrecht',
					href: '/rechtsgebiete/erbrecht'
				},
				{
					title: 'Sozialrecht',
					href: '/rechtsgebiete/sozialrecht'
				},
				{
					title: 'Verkehrsrecht',
					href: '/rechtsgebiete/verkehrsrecht'
				}
			]
		}
	];

	let menuItems = $derived(
		MENU_ITMES.map((item) => ({
			...item,
			active: item.href
				? $page.url.pathname.includes(item.href)
				: (item?.items || []).some((subItem) => $page.url.pathname.includes(subItem.href))
		}))
	);

	let open = $state(false);
</script>

<header class="absolute inset-x-0 top-0 z-50 {isTransparent ? 'bg-transparent' : 'bg-white'}">
	<div class="container flex items-center justify-between py-5">
		<div class="flex items-center gap-1.5">
			<Sheet.Root bind:open>
				<Sheet.Trigger asChild let:builder>
					<Button
						builders={[builder]}
						variant="ghost"
						class="px-0 text-base text-primary hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 lg:hidden"
					>
						<MenuIcon class="h-7 w-7" />
						<span class="sr-only">Toggle Menu</span>
					</Button>
				</Sheet.Trigger>
				<Sheet.Content side="left" class="pr-0">
					<ScrollArea orientation="both" class="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
						<div class="flex flex-col space-y-3">
							{#each menuItems as item}
								{#if item.href && item.items}
									<div
										class="flex items-center gap-1 font-medium {item.active
											? 'text-primary'
											: 'text-gray-700 hover:text-gray-900'}"
									>
										<a href={item.href}>
											{item.title}
										</a>
										<DropdownMenu.Root>
											<DropdownMenu.Trigger>
												<ChevronDownIcon class="h-4 w-4" />
											</DropdownMenu.Trigger>
											<DropdownMenu.Content class="w-52" align="end">
												<DropdownMenu.Group>
													{#each item.items as subItem}
														<DropdownMenu.Item href={subItem.href}>
															{subItem.title}
														</DropdownMenu.Item>
													{/each}
												</DropdownMenu.Group>
											</DropdownMenu.Content>
										</DropdownMenu.Root>
									</div>
								{:else if item.items}
									<DropdownMenu.Root>
										<DropdownMenu.Trigger
											class="flex items-center gap-1 font-medium {item.active
												? 'text-primary'
												: 'text-gray-700 hover:text-gray-900'}"
										>
											{item.title}
											<ChevronDownIcon class="h-4 w-4" />
										</DropdownMenu.Trigger>
										<DropdownMenu.Content class="w-52" align="end">
											<DropdownMenu.Group>
												{#each item.items as subItem}
													<DropdownMenu.Item href={subItem.href}>{subItem.title}</DropdownMenu.Item>
												{/each}
											</DropdownMenu.Group>
										</DropdownMenu.Content>
									</DropdownMenu.Root>
								{:else}
									<a
										class="font-medium {item.active
											? 'text-primary'
											: 'text-gray-700 hover:text-gray-900'}"
										href={item.href}
									>
										{item.title}
									</a>
								{/if}
							{/each}
						</div>
					</ScrollArea>
				</Sheet.Content>
			</Sheet.Root>
			<a href="/">
				<enhanced:img
					src="../../assets/logo.svg"
					alt="Frag den Lenßen"
					width="167"
					height="64"
					class="h-12 w-auto sm:h-14 lg:h-16"
				/>
				<span class="sr-only"> Frag den Lenßen </span>
			</a>
		</div>
		<div class="flex items-center gap-2 md:gap-4">
			{#each menuItems as item}
				{#if item.href && item.items}
					<div
						class="hidden items-center gap-1 px-4 font-medium {item.active
							? 'text-primary'
							: 'text-gray-700 hover:text-gray-900'} lg:flex"
					>
						<a href={item.href}>
							{item.title}
						</a>
						<DropdownMenu.Root>
							<DropdownMenu.Trigger>
								<ChevronDownIcon class="size-5" />
							</DropdownMenu.Trigger>
							<DropdownMenu.Content class="w-52" align="end">
								<DropdownMenu.Group>
									{#each item.items as subItem}
										<DropdownMenu.Item href={subItem.href}>
											{subItem.title}
										</DropdownMenu.Item>
									{/each}
								</DropdownMenu.Group>
							</DropdownMenu.Content>
						</DropdownMenu.Root>
					</div>
				{:else if item.items}
					<DropdownMenu.Root>
						<DropdownMenu.Trigger
							class="hidden items-center gap-1 px-4 font-medium {item.active
								? 'text-primary'
								: 'text-gray-700 hover:text-gray-900'} lg:flex"
						>
							{item.title}
							<ChevronDownIcon class="size-5" />
						</DropdownMenu.Trigger>
						<DropdownMenu.Content class="w-52" align="end">
							<DropdownMenu.Group>
								{#each item.items as subItem}
									<DropdownMenu.Item href={subItem.href}>{subItem.title}</DropdownMenu.Item>
								{/each}
							</DropdownMenu.Group>
						</DropdownMenu.Content>
					</DropdownMenu.Root>
				{:else}
					<a
						class="hidden px-4 font-medium lg:block {item.active
							? 'text-primary'
							: 'text-gray-700 hover:text-gray-900'}"
						href={item.href}
					>
						{item.title}
					</a>
				{/if}
			{/each}
			<a
				class="border border-solid border-primary bg-transparent px-2.5 py-2 text-xs text-primary sm:px-6 sm:text-sm md:text-base"
				rel="external"
				href="/chat"
			>
				<span class="block sm:hidden">Stell Deine Frage</span>
				<span class="hidden sm:block">Zum digitalen Ingo</span>
			</a>
		</div>
	</div>
</header>
