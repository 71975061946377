<footer class="bg-stone-800" aria-labelledby="footer-heading">
	<h2 id="footer-heading" class="sr-only">Footer</h2>
	<div class="container px-6 py-16 lg:px-8">
		<div class="flex flex-col md:flex-row md:items-start md:justify-between">
			<div class="space-y-8">
				<enhanced:img
					width="146"
					height="56"
					class="h-14 w-auto max-w-full"
					src="../../assets/logo_white.svg"
					alt="Advopedia"
				/>
				<div class="flex space-x-6">
					<a
						href="https://www.facebook.com/profile.php?id=61567170705323"
						target="_blank"
						class="text-gray-300 hover:text-white"
					>
						<span class="sr-only">Facebook</span>
						<svg
							class="size-6"
							fill="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><title>Facebook</title><path
								d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"
							/></svg
						>
					</a>
					<a
						href="https://www.instagram.com/fragdenlenssen/"
						target="_blank"
						class="text-gray-300 hover:text-white"
					>
						<span class="sr-only">Instagram</span>
						<svg
							class="size-6"
							role="img"
							fill="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><title>Instagram</title><path
								d="M7.0301.084c-1.2768.0602-2.1487.264-2.911.5634-.7888.3075-1.4575.72-2.1228 1.3877-.6652.6677-1.075 1.3368-1.3802 2.127-.2954.7638-.4956 1.6365-.552 2.914-.0564 1.2775-.0689 1.6882-.0626 4.947.0062 3.2586.0206 3.6671.0825 4.9473.061 1.2765.264 2.1482.5635 2.9107.308.7889.72 1.4573 1.388 2.1228.6679.6655 1.3365 1.0743 2.1285 1.38.7632.295 1.6361.4961 2.9134.552 1.2773.056 1.6884.069 4.9462.0627 3.2578-.0062 3.668-.0207 4.9478-.0814 1.28-.0607 2.147-.2652 2.9098-.5633.7889-.3086 1.4578-.72 2.1228-1.3881.665-.6682 1.0745-1.3378 1.3795-2.1284.2957-.7632.4966-1.636.552-2.9124.056-1.2809.0692-1.6898.063-4.948-.0063-3.2583-.021-3.6668-.0817-4.9465-.0607-1.2797-.264-2.1487-.5633-2.9117-.3084-.7889-.72-1.4568-1.3876-2.1228C21.2982 1.33 20.628.9208 19.8378.6165 19.074.321 18.2017.1197 16.9244.0645 15.6471.0093 15.236-.005 11.977.0014 8.718.0076 8.31.0215 7.0301.0839m.1402 21.6932c-1.17-.0509-1.8053-.2453-2.2287-.408-.5606-.216-.96-.4771-1.3819-.895-.422-.4178-.6811-.8186-.9-1.378-.1644-.4234-.3624-1.058-.4171-2.228-.0595-1.2645-.072-1.6442-.079-4.848-.007-3.2037.0053-3.583.0607-4.848.05-1.169.2456-1.805.408-2.2282.216-.5613.4762-.96.895-1.3816.4188-.4217.8184-.6814 1.3783-.9003.423-.1651 1.0575-.3614 2.227-.4171 1.2655-.06 1.6447-.072 4.848-.079 3.2033-.007 3.5835.005 4.8495.0608 1.169.0508 1.8053.2445 2.228.408.5608.216.96.4754 1.3816.895.4217.4194.6816.8176.9005 1.3787.1653.4217.3617 1.056.4169 2.2263.0602 1.2655.0739 1.645.0796 4.848.0058 3.203-.0055 3.5834-.061 4.848-.051 1.17-.245 1.8055-.408 2.2294-.216.5604-.4763.96-.8954 1.3814-.419.4215-.8181.6811-1.3783.9-.4224.1649-1.0577.3617-2.2262.4174-1.2656.0595-1.6448.072-4.8493.079-3.2045.007-3.5825-.006-4.848-.0608M16.953 5.5864A1.44 1.44 0 1 0 18.39 4.144a1.44 1.44 0 0 0-1.437 1.4424M5.8385 12.012c.0067 3.4032 2.7706 6.1557 6.173 6.1493 3.4026-.0065 6.157-2.7701 6.1506-6.1733-.0065-3.4032-2.771-6.1565-6.174-6.1498-3.403.0067-6.156 2.771-6.1496 6.1738M8 12.0077a4 4 0 1 1 4.008 3.9921A3.9996 3.9996 0 0 1 8 12.0077"
							/></svg
						>
					</a>
					<a
						href="https://www.youtube.com/@FragdenLen%C3%9Fen"
						target="_blank"
						class="text-gray-300 hover:text-white"
					>
						<span class="sr-only">YouTube</span>
						<svg
							class="size-6"
							fill="currentColor"
							role="img"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><title>YouTube</title><path
								d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
							/></svg
						>
					</a>
					<a
						href="https://www.google.com/maps/place/Frag+den+Len%C3%9Fen/@51.175807,10.4541194,6z/data=!3m1!4b1!4m6!3m5!1s0x672a95d70bc9b081:0x4f27ca307e35016b!8m2!3d51.175807!4d10.4541194!16s%2Fg%2F11wk0915mp"
						target="_blank"
						class="text-gray-300 hover:text-white"
					>
						<span class="sr-only">Google Maps</span>
						<svg
							class="size-6"
							fill="currentColor"
							role="img"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><title>Google Maps</title><path
								d="M19.527 4.799c1.212 2.608.937 5.678-.405 8.173-1.101 2.047-2.744 3.74-4.098 5.614-.619.858-1.244 1.75-1.669 2.727-.141.325-.263.658-.383.992-.121.333-.224.673-.34 1.008-.109.314-.236.684-.627.687h-.007c-.466-.001-.579-.53-.695-.887-.284-.874-.581-1.713-1.019-2.525-.51-.944-1.145-1.817-1.79-2.671L19.527 4.799zM8.545 7.705l-3.959 4.707c.724 1.54 1.821 2.863 2.871 4.18.247.31.494.622.737.936l4.984-5.925-.029.01c-1.741.601-3.691-.291-4.392-1.987a3.377 3.377 0 0 1-.209-.716c-.063-.437-.077-.761-.004-1.198l.001-.007zM5.492 3.149l-.003.004c-1.947 2.466-2.281 5.88-1.117 8.77l4.785-5.689-.058-.05-3.607-3.035zM14.661.436l-3.838 4.563a.295.295 0 0 1 .027-.01c1.6-.551 3.403.15 4.22 1.626.176.319.323.683.377 1.045.068.446.085.773.012 1.22l-.003.016 3.836-4.561A8.382 8.382 0 0 0 14.67.439l-.009-.003zM9.466 5.868L14.162.285l-.047-.012A8.31 8.31 0 0 0 11.986 0a8.439 8.439 0 0 0-6.169 2.766l-.016.018 3.665 3.084z"
							/></svg
						>
					</a>
					<a
						href="https://www.tiktok.com/@fragdenlenssen"
						target="_blank"
						class="text-gray-300 hover:text-white"
					>
						<span class="sr-only">TikTok</span>
						<svg
							class="size-6"
							fill="currentColor"
							role="img"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><title>TikTok</title><path
								d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"
							/></svg
						>
					</a>
				</div>
				<p class="text-xs leading-5 text-gray-300">
					&copy; {new Date().getFullYear()} Ein Service der
					<a href="https://advopedia.de" target="_blank">Advopedia GmbH</a>
					& <a href="https://www.ainavio.com" target="_blank">Ainavio GmbH</a>
				</p>
			</div>
			<div class="mt-16 md:mt-0">
				<div class="md:grid md:grid-cols-2 md:gap-20 lg:grid-cols-3">
					<div class="mt-4 md:mt-0">
						<b class="mb-4 block text-sm text-white">Über Frag den Lenßen</b>
						<ul role="list" class="space-y-4">
							<li>
								<a href="/ueber-uns" class="text-sm leading-6 text-gray-300 hover:text-white">
									Über uns
								</a>
							</li>
							<li>
								<a href="/so-fragst-du" class="text-sm leading-6 text-gray-300 hover:text-white">
									So fragst Du
								</a>
							</li>
							<li>
								<a href="/impressum" class="text-sm leading-6 text-gray-300 hover:text-white">
									Impressum
								</a>
							</li>
						</ul>
					</div>
					<div class="mt-12 md:mt-0">
						<b class="mb-4 block text-sm text-white">Rechtsgebiete</b>
						<ul role="list" class="space-y-4">
							<li>
								<a
									href="/rechtsgebiete/erbrecht"
									class="text-sm leading-6 text-gray-300 hover:text-white"
								>
									Erbrecht
								</a>
							</li>
							<li>
								<a
									href="/rechtsgebiete/sozialrecht"
									class="text-sm leading-6 text-gray-300 hover:text-white"
								>
									Sozialrecht
								</a>
							</li>
							<li>
								<a
									href="/rechtsgebiete/verkehrsrecht"
									class="text-sm leading-6 text-gray-300 hover:text-white"
								>
									Verkehrsrecht
								</a>
							</li>
						</ul>
					</div>
					<div class="mt-12 md:mt-0">
						<b class="mb-4 block text-sm text-white">Sicherheit</b>
						<ul role="list" class="space-y-4">
							<li>
								<a href="/agb" class="text-sm leading-6 text-gray-300 hover:text-white">
									AGB & rechtliche Hinweise
								</a>
							</li>
							<li>
								<a href="/agb#datenschutz" class="text-sm leading-6 text-gray-300 hover:text-white">
									Datenschutz
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
